import { useBlissbookRendererContext } from '@blissbook/lib/blissbook/renderer/context'
import {
  type HeadingNode,
  getAudienceExpressionFromNode,
  getIndentClassName,
} from '@blissbook/lib/document/types'
import { stringifyAudienceExpression } from '@blissbook/lib/expression'
import type React from 'react'
import type { NodeViewerComponent } from '.'

export const HeadingNodeViewer: NodeViewerComponent = ({ children, node }) => {
  const { showExpressions } = useBlissbookRendererContext()
  const audienceExpression = getAudienceExpressionFromNode(node)
  const { attrs } = node as HeadingNode
  const { indent, level, textAlign } = attrs || {}
  const TagName = `h${level}` as React.ElementType
  return (
    <TagName
      className={getIndentClassName(indent)}
      data-audience-expression={
        showExpressions && stringifyAudienceExpression(audienceExpression)
      }
      style={{ textAlign }}
    >
      {children || <br />}
    </TagName>
  )
}

import { useEmailBrandingContext } from '@blissbook/lib/email'
import type React from 'react'
import type { CSSProperties, ReactNode } from 'react'

export const Paragraph: React.FC<{
  style?: CSSProperties
  className?: string
}> = ({ children = <br />, style, ...props }) => {
  const { bodyFont } = useEmailBrandingContext()
  return (
    <p
      {...props}
      style={{
        fontFamily: bodyFont,
        margin: 0,
        ...style,
      }}
    >
      {children}
    </p>
  )
}

export function prependEmailMargin(content: ReactNode) {
  return (
    <>
      <Paragraph />
      {content}
    </>
  )
}

import type { TableCellNode } from '@blissbook/lib/document'
import type { NodeViewerComponent } from '.'

export const TableCellNodeViewer: NodeViewerComponent = ({
  children,
  node,
}) => {
  const tableCell = node as TableCellNode
  const { backgroundColor, colspan, height, rowspan, valign, width } =
    tableCell.attrs
  return (
    <td
      colSpan={colspan}
      rowSpan={rowspan}
      style={{
        backgroundColor,
        height,
        verticalAlign: valign,
        width,
      }}
    >
      {children}
    </td>
  )
}

import type { IEmailPerson } from '@blissbook/lib/email'
import type { ReactNode } from 'react'

export function renderFrom(from?: IEmailPerson): ReactNode {
  if (!from) return 'The Blissbook Team'
  return (
    <>
      {from.fullName}
      <br />
      {from.email}
    </>
  )
}

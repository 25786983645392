import { useBlissbookRendererContext } from '@blissbook/lib/blissbook/renderer/context'
import {
  type TableNode,
  getAudienceExpressionFromNode,
  getTableClassName,
  getTableCss,
  getTableNodeAttributes,
} from '@blissbook/lib/document'
import { stringifyAudienceExpression } from '@blissbook/lib/expression'
import type { NodeViewerComponent } from '.'

export const TableNodeViewer: NodeViewerComponent = ({ children, node }) => {
  const { showExpressions } = useBlissbookRendererContext()
  const audienceExpression = getAudienceExpressionFromNode(node)
  const table = node as TableNode
  const attrs = getTableNodeAttributes(table.attrs)
  const { borderColor } = attrs
  return (
    <div className='tw-overflow-auto'>
      <table
        className={getTableClassName(table.attrs)}
        css={getTableCss(table.attrs)}
        data-audience-expression={
          showExpressions && stringifyAudienceExpression(audienceExpression)
        }
        style={{ borderColor }}
      >
        {children}
      </table>
    </div>
  )
}

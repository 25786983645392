import { getContrastColor } from '@blissbook/common/branding/color'
import type { Maybe } from '@blissbook/ui/application/graph'
import isNil from 'lodash/isNil'
import { type ReactNode, useMemo } from 'react'

export const emailFonts = {
  'Sans Serif': 'arial, helvetica, sans-serif',
  Serif: "'times new roman', serif",
  'Fixed Width': 'monospace, monospace',
  Wide: "'arial black', sans-serif",
  Narrow: "'arial narrow', sans-serif",
  Garamond: 'garamond, serif',
  Georgia: 'georgia, serif',
  Tahoma: 'tahoma, sans-serif',
  'Trebuchet MS': "'trebuchet ms', sans-serif",
  Verdana: 'verdana, sans-serif',
}

const defaultEmailFont = Object.values(emailFonts)[0]

export type EmailImage = {
  id?: number
  height: number
  url: string
  width: number
}

export type EmailFooterLink = {
  text: string
  url: string
}

export type EmailBrandingInput = {
  bannerImage?: Maybe<EmailImage>
  bodyFont?: Maybe<string>
  footerLinks?: Maybe<EmailFooterLink[]>
  footerText?: Maybe<ReactNode>
  logoImage?: Maybe<EmailImage>
  primaryColor?: Maybe<string>
}

export type EmailBranding = {
  backgroundColor: string
  bannerImage?: Maybe<EmailImage>
  bodyFont: string
  buttonColor: string
  buttonTextColor: string
  footerLinks?: EmailFooterLink[]
  footerText?: Maybe<ReactNode>
  footerTextColor: string
  hrColor: string
  linkColor: string
  logoImage?: Maybe<EmailImage>
  primaryColor: string
  textColor: string
}

const getValue = <T>(value: Maybe<T> | undefined, defaultValue: T) =>
  isNil(value) ? defaultValue : value

export const getEmailBranding = (
  input: EmailBrandingInput = {},
): EmailBranding => {
  const primaryColor = getValue(input.primaryColor, '#4e4ed8')
  return {
    backgroundColor: '#f2f2f2',
    bodyFont: getValue(input.bodyFont, defaultEmailFont),
    buttonColor: primaryColor,
    buttonTextColor: getContrastColor(primaryColor, ['white', '#333']),
    footerTextColor: '#727272',
    hrColor: '#ebebeb',
    linkColor: '#1155CC',
    primaryColor,
    textColor: '#333333',
    ...input,
  }
}

export const useEmailBranding = (input?: EmailBrandingInput) =>
  useMemo(() => getEmailBranding(input), [input])

export const blissbookEmailBranding = getEmailBranding({
  logoImage: {
    height: 48,
    url: 'https://blissbook.com/images/email/logo.png',
    width: 301,
  },
})

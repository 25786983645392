// @ts-ignore: no types available
import compoundSubject from 'compound-subject'
import { Paragraph } from './paragraph'

export function renderCcEmailsNote(emails: string[]) {
  if (!emails.length) return null

  const toEmails = compoundSubject(emails).delimitAll().make()
  return (
    <Paragraph>
      <em>
        <strong>Note: A copy of this email was sent to {toEmails}.</strong>
      </em>
    </Paragraph>
  )
}

import {
  type ImageNode,
  getEmbedRenderProps,
} from '@blissbook/lib/document/types'
import type { NodeViewerComponent } from '.'
import { MarksViewer } from '../marks'

export const ImageNodeViewer: NodeViewerComponent = ({ node }) => {
  const { attrs, marks } = node as ImageNode
  const { src, width } = attrs
  const embed = getEmbedRenderProps(attrs)

  return (
    <MarksViewer marks={marks}>
      <img {...embed} alt='Preview' src={src} width={width} />
    </MarksViewer>
  )
}

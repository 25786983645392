import type { MarkViewerComponent } from '@blissbook/lib/document/renderer/marks'
import type { LinkMark } from '@blissbook/lib/document/types'

export const LinkMarkViewer: MarkViewerComponent = ({ mark, ...props }) => {
  const { attrs } = mark as LinkMark
  const { href } = attrs

  // Relative links (within a document)
  if (href[0] === '#') {
    return <a {...props} href={href} />
  }
  // External links

  return (
    <a
      {...props}
      href={href}
      rel='noopener noreferrer nofollow'
      target='_blank'
    />
  )
}

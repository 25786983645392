import type { Node } from '@blissbook/lib/document/types'
import type React from 'react'
import { BannerNodeViewer } from './banner'
import { BulletListNodeViewer } from './bulletList'
import { HardBreakNodeViewer } from './hardBreak'
import { HeadingNodeViewer } from './heading'
import { HorizontalRultNodeViewer } from './horizontalRule'
import { ImageNodeViewer } from './image'
import { ListItemNodeViewer } from './listItem'
import { OrderedListNodeViewer } from './orderedList'
import { ParagraphNodeViewer } from './paragraph'
import { PdfNodeViewer } from './pdf'
import { ReadMoreNodeViewer } from './readMore'
import { TableNodeViewer } from './table'
import { TableCellNodeViewer } from './tableCell'
import { TableRowNodeViewer } from './tableRow'
import { TextNodeViewer } from './text'
import { VideoNodeViewer } from './video'

export type NodeViewerProps = {
  node: Node
}

export type NodeViewerComponent = React.FC<NodeViewerProps>

export const nodeViewersByType: Record<string, NodeViewerComponent> = {
  banner: BannerNodeViewer,
  bulletList: BulletListNodeViewer,
  hardBreak: HardBreakNodeViewer,
  heading: HeadingNodeViewer,
  horizontalRule: HorizontalRultNodeViewer,
  image: ImageNodeViewer,
  listItem: ListItemNodeViewer,
  orderedList: OrderedListNodeViewer,
  paragraph: ParagraphNodeViewer,
  pdf: PdfNodeViewer,
  readMore: ReadMoreNodeViewer,
  table: TableNodeViewer,
  tableCell: TableCellNodeViewer,
  tableRow: TableRowNodeViewer,
  text: TextNodeViewer,
  video: VideoNodeViewer,
}

export const NodeViewer: NodeViewerComponent = (props) => {
  const { node } = props

  const NodeTypeViewer = nodeViewersByType[node.type]
  if (!NodeTypeViewer) {
    console.error(`Unknown NodeViewer type ${node.type}`)
    return null
  }

  return (
    <NodeTypeViewer {...props}>
      {'content' in node && renderContent(node.content)}
    </NodeTypeViewer>
  )
}

export const renderContent = (content: Node[]) => {
  return content.map((node, index) => <NodeViewer key={index} node={node} />)
}

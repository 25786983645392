import { useBlissbookRendererContext } from '@blissbook/lib/blissbook/renderer/context'
import { getAudienceExpressionFromNode } from '@blissbook/lib/document/types'
import { stringifyAudienceExpression } from '@blissbook/lib/expression'
import type { NodeViewerComponent } from '.'

export const ReadMoreNodeViewer: NodeViewerComponent = ({ children, node }) => {
  const { showExpressions } = useBlissbookRendererContext()
  const audienceExpression = getAudienceExpressionFromNode(node)
  return (
    <div
      className='read-more'
      data-audience-expression={
        showExpressions && stringifyAudienceExpression(audienceExpression)
      }
    >
      <div className='read-more-content'>{children}</div>
    </div>
  )
}

import { useEmailBrandingContext } from '@blissbook/lib/email'
import type React from 'react'
import type { CSSProperties } from 'react'

export const HorizontalRule: React.FC<{
  style?: CSSProperties
}> = ({ style, ...props }) => {
  const { hrColor } = useEmailBrandingContext()
  return (
    <hr
      {...props}
      style={{
        borderTop: `2px solid ${hrColor}`,
        borderRight: `0 solid ${hrColor}`,
        borderBottom: `0 solid ${hrColor}`,
        borderLeft: `0 solid ${hrColor}`,
        margin: '1em 0',
        ...style,
      }}
    />
  )
}

import { useBlissbookRendererContext } from '@blissbook/lib/blissbook/renderer/context'
import {
  type ParagraphNode,
  getAudienceExpressionFromNode,
  getIndentClassName,
} from '@blissbook/lib/document/types'
import { stringifyAudienceExpression } from '@blissbook/lib/expression'
import type { NodeViewerComponent } from '.'

export const ParagraphNodeViewer: NodeViewerComponent = ({
  children,
  node,
}) => {
  const { showExpressions } = useBlissbookRendererContext()
  const audienceExpression = getAudienceExpressionFromNode(node)
  const { attrs } = node as ParagraphNode
  const { indent, textAlign } = attrs || {}

  return (
    <p
      className={getIndentClassName(indent)}
      data-audience-expression={
        showExpressions && stringifyAudienceExpression(audienceExpression)
      }
      style={{ textAlign }}
    >
      {children || <br />}
    </p>
  )
}

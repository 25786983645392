import { useEmailBrandingContext } from '@blissbook/lib/email'
import type React from 'react'
import type { CSSProperties } from 'react'

export type LinkProps = {
  className?: string
  href: string
  style?: CSSProperties
}

export const Link: React.FC<LinkProps> = ({ children, style, ...props }) => {
  const { linkColor } = useEmailBrandingContext()
  return (
    <a
      {...props}
      rel='noopener noreferrer'
      target='_blank'
      style={{
        color: linkColor,
        ...style,
      }}
    >
      <span style={{ color: linkColor }}>{children}</span>
    </a>
  )
}
